/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Safeguardr API Documentation
 * NestJS Authentication with OAuth2.0
 * OpenAPI spec version: 0.0.2
 */
import { httpClient } from '..';
import type { BodyType } from '../api';

export type FindAllOrder200AllOf = {
  data?: Order[];
};

export type FindAllOrder200 = OffsetPaginationResponse & FindAllOrder200AllOf;

export type FindAllOrderParams = {
  /**
   * From date filter
   */
  from?: string;
  /**
   * To date filter
   */
  to?: string;
  /**
   * The search query
   */
  search?: string;
  /**
   * Indicates whether to include deleted items in the results or not
   */
  withDeleted?: boolean;
  /**
 * The `relations` property is used to specify which related
entities should be included in the query
results.
 */
  relations?: string[];
  /**
 * The `fields` property is used to specify which
entities field should be included in the query
results.
 */
  fields?: string[];
  /**
   * Results page you want to retrieve (0..N)
   */
  page?: number;
  /**
   * Number of results per page
   */
  limit?: number;
  /**
   * Sorting order
   */
  order?: QueryOrder;
  /**
   * Sorting criteria
   */
  sortBy?: string;
};

export type CategoriesControllerUpdate200 = { [key: string]: any };

export type FindAllCategory200AllOf = {
  data?: Category[];
};

export type FindAllCategory200 = OffsetPaginationResponse & FindAllCategory200AllOf;

export type FindAllCategoryParams = {
  /**
   * From date filter
   */
  from?: string;
  /**
   * To date filter
   */
  to?: string;
  /**
   * The search query
   */
  search?: string;
  /**
   * Indicates whether to include deleted items in the results or not
   */
  withDeleted?: boolean;
  /**
 * The `relations` property is used to specify which related
entities should be included in the query
results.
 */
  relations?: string[];
  /**
 * The `fields` property is used to specify which
entities field should be included in the query
results.
 */
  fields?: string[];
  /**
   * Results page you want to retrieve (0..N)
   */
  page?: number;
  /**
   * Number of results per page
   */
  limit?: number;
  /**
   * Sorting order
   */
  order?: QueryOrder;
  /**
   * Sorting criteria
   */
  sortBy?: string;
};

export type FindAllProduct200AllOf = {
  data?: Product[];
};

export type FindAllProduct200 = OffsetPaginationResponse & FindAllProduct200AllOf;

export type FindAllProductParams = {
  /**
   * From date filter
   */
  from?: string;
  /**
   * To date filter
   */
  to?: string;
  /**
   * The search query
   */
  search?: string;
  /**
   * Indicates whether to include deleted items in the results or not
   */
  withDeleted?: boolean;
  /**
 * The `relations` property is used to specify which related
entities should be included in the query
results.
 */
  relations?: string[];
  /**
 * The `fields` property is used to specify which
entities field should be included in the query
results.
 */
  fields?: string[];
  /**
   * Results page you want to retrieve (0..N)
   */
  page?: number;
  /**
   * Number of results per page
   */
  limit?: number;
  /**
   * Sorting order
   */
  order?: QueryOrder;
  /**
   * Sorting criteria
   */
  sortBy?: string;
  /**
   * Category of the product
   */
  categoryId?: string;
  /**
   * Minimum price of the product
   */
  priceMin?: number;
  /**
   * Maximum price of the product
   */
  priceMax?: number;
  /**
   * Availability of the product
   */
  inStock?: boolean;
  characteristicsFilter?: string[];
};

export type FindAllUser200AllOf = {
  data?: User[];
};

export type FindAllUser200 = OffsetPaginationResponse & FindAllUser200AllOf;

export type FindAllUserParams = {
  /**
   * From date filter
   */
  from?: string;
  /**
   * To date filter
   */
  to?: string;
  /**
   * The search query
   */
  search?: string;
  /**
   * Indicates whether to include deleted items in the results or not
   */
  withDeleted?: boolean;
  /**
 * The `relations` property is used to specify which related
entities should be included in the query
results.
 */
  relations?: string[];
  /**
 * The `fields` property is used to specify which
entities field should be included in the query
results.
 */
  fields?: string[];
  /**
   * Results page you want to retrieve (0..N)
   */
  page?: number;
  /**
   * Number of results per page
   */
  limit?: number;
  /**
   * Sorting order
   */
  order?: QueryOrder;
  /**
   * Sorting criteria
   */
  sortBy?: string;
};

export type CheckDiskAndDbHealth503Info = {
  [key: string]: {
    status?: string;
    [key: string]: string;
  };
} | null;

export type CheckDiskAndDbHealth503Error = {
  [key: string]: {
    status?: string;
    [key: string]: string;
  };
} | null;

export type CheckDiskAndDbHealth503Details = {
  [key: string]: {
    status?: string;
    [key: string]: string;
  };
};

export type CheckDiskAndDbHealth503 = {
  details?: CheckDiskAndDbHealth503Details;
  error?: CheckDiskAndDbHealth503Error;
  info?: CheckDiskAndDbHealth503Info;
  status?: string;
};

export type OrderProductsItem = { [key: string]: any };

export interface OrderUser {
  email: string;
  id?: string;
  name: string;
  phone: string;
}

export interface Order {
  /** The date that the entity was created */
  createdAt?: string;
  /** The date that the entity was soft-deleted. Nullable because it's not set until the entity is soft-deleted. */
  deletedAt?: string | null;
  /** The unique id of the entity */
  id: string;
  /** To enable or disable the entity */
  isActive?: boolean;
  products: OrderProductsItem[];
  user: OrderUser;
}

export interface CreateOrderProduct {
  productId: string;
  quantity: number;
}

export interface CreateOrderUser {
  email: string;
  name: string;
  phone: string;
}

export interface CreateOrderDto {
  products: CreateOrderProduct[];
  user: CreateOrderUser;
}

export interface UpdatePromotionDto {
  categoryId?: string;
  discount?: number;
  endDate?: string;
  image?: string;
  name?: string;
  productId?: string;
  startDate?: string;
}

export interface CreatePromotionDto {
  categoryId?: string;
  discount: number;
  endDate?: string;
  image: string;
  name: string;
  productId?: string;
  startDate: string;
}

export interface CreateFilterDto {
  /** Array of the category filters */
  blocks: CategoryFilterBlock[];
  /** Category */
  categoryId: string;
}

export interface UpdateCategoryDto {
  filter?: CreateCategoryFilterDto[];
  /** Image of category */
  image?: string;
  /** Name of category */
  name?: string;
  /** Parent category id */
  parentId?: string;
}

export interface CreateFilterVariantDto {
  /** Value of variant */
  name: string;
}

export type CategoryFilterType = (typeof CategoryFilterType)[keyof typeof CategoryFilterType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryFilterType = {
  checkbox: 'checkbox',
  radio: 'radio',
  range: 'range',
} as const;

export interface CreateCategoryFilterDto {
  /** Name of filter */
  name: string;
  type: CategoryFilterType;
  /** Variants of filter when type is checkbox */
  variants?: CreateFilterVariantDto[];
}

export interface CreateCategoryDto {
  filter?: CreateCategoryFilterDto[];
  /** Image of category */
  image?: string;
  /** Name of category */
  name: string;
  /** Parent category id */
  parentId?: string;
}

/**
 * Product attributes
 */
export type UpdateProductDtoAttributes = { [key: string]: any };

export interface UpdateProductDto {
  /** Product article */
  article?: string;
  /** Product attributes */
  attributes?: UpdateProductDtoAttributes;
  /** Product brand name */
  brand?: string;
  /** Category id */
  categoryId?: string;
  /** Product description */
  description?: string;
  /** Product images */
  images?: string[];
  /** Product name */
  name?: string;
  /** Product price */
  price?: CreateProductPriceDto;
  /** Product stock */
  stock?: number;
  /** Youtube video link */
  video?: string;
}

export type CategoryProducts = { [key: string]: any };

export interface Category {
  children: Category[];
  /** The date that the entity was created */
  createdAt?: string;
  /** The date that the entity was soft-deleted. Nullable because it's not set until the entity is soft-deleted. */
  deletedAt?: string | null;
  filters?: CategoryFilters;
  /** The unique id of the entity */
  id: string;
  /** URL of the image */
  image?: string;
  /** To enable or disable the entity */
  isActive?: boolean;
  /** Name of the category */
  name: string;
  parent?: Category;
  products: CategoryProducts;
  promotion?: Promotion;
}

/**
 * Product attributes/characteristics
 */
export type ProductAttributes = { [key: string]: any };

export interface ProductMedia {
  /** URL of the image */
  images: string[];
  /** URL of the video */
  video: string;
}

export interface ProductPrice {
  /** The date that the entity was created */
  createdAt?: string;
  /** The date that the entity was soft-deleted. Nullable because it's not set until the entity is soft-deleted. */
  deletedAt?: string | null;
  /** Discount of the product */
  discount?: number;
  /** Discount of the category promotion */
  discountCategory?: number;
  /** Discount of the product promotion */
  discountProduct?: number;
  /** Price of the product in dollars */
  dollar?: number;
  /** Price of the product in dollars */
  dollarWithDiscount?: number;
  /** Price of the product in euros */
  euro?: number;
  /** Price of the product in euros */
  euroWithDiscount?: number;
  /** The unique id of the entity */
  id: string;
  /** To enable or disable the entity */
  isActive?: boolean;
  /** Price of the product in rubles */
  ruble?: number;
  /** Price of the product in rubles */
  rubleWithDiscount?: number;
}

export interface Product {
  /** Article of the product */
  article?: string;
  /** Product attributes/characteristics */
  attributes: ProductAttributes;
  /** Brand of the product */
  brand?: string;
  category: Category;
  /** The date that the entity was created */
  createdAt?: string;
  /** The date that the entity was soft-deleted. Nullable because it's not set until the entity is soft-deleted. */
  deletedAt?: string | null;
  /** Description of the product */
  description?: string;
  /** The unique id of the entity */
  id: string;
  /** To enable or disable the entity */
  isActive?: boolean;
  media: ProductMedia;
  /** Name of the product */
  name: string;
  price: ProductPrice;
  /** Stock of the product */
  stock: number;
}

export interface Promotion {
  category?: Category;
  /** The date that the entity was created */
  createdAt?: string;
  /** The date that the entity was soft-deleted. Nullable because it's not set until the entity is soft-deleted. */
  deletedAt?: string | null;
  discount: number;
  endDate?: string;
  /** The unique id of the entity */
  id: string;
  image: string;
  /** To enable or disable the entity */
  isActive?: boolean;
  name: string;
  product?: Product;
  startDate: string;
}

export interface CategoryFilters {
  /** Filter blocks */
  blocks: CategoryFilterBlock[];
  category: Category;
  /** The date that the entity was created */
  createdAt?: string;
  /** The date that the entity was soft-deleted. Nullable because it's not set until the entity is soft-deleted. */
  deletedAt?: string | null;
  /** The unique id of the entity */
  id: string;
  /** To enable or disable the entity */
  isActive?: boolean;
}

/**
 * Filter type
 */
export type CategoryFilterBlockType = (typeof CategoryFilterBlockType)[keyof typeof CategoryFilterBlockType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryFilterBlockType = {
  checkbox: 'checkbox',
  radio: 'radio',
  range: 'range',
} as const;

export interface CategoryFilterVariant {
  /** Variant name */
  name: string;
}

export interface CategoryFilterBlock {
  /** Filter name */
  name: string;
  /** Filter type */
  type: CategoryFilterBlockType;
  /** Filter variants if type is "checkbox" */
  variants?: CategoryFilterVariant[];
}

/**
 * Product attributes
 */
export type CreateProductDtoAttributes = { [key: string]: any };

export interface CreateProductPriceDto {
  /** Product discount in percents */
  discount?: number;
  /** Product price in dollars */
  dollar?: number;
  /** Product price in euros */
  euro?: number;
  /** Product price in rubles */
  ruble?: number;
}

export interface CreateProductDto {
  /** Product article */
  article?: string;
  /** Product attributes */
  attributes: CreateProductDtoAttributes;
  /** Product brand name */
  brand?: string;
  /** Category id */
  categoryId: string;
  /** Product description */
  description?: string;
  /** Product images */
  images: string[];
  /** Product name */
  name: string;
  /** Product price */
  price: CreateProductPriceDto;
  /** Product stock */
  stock: number;
  /** Youtube video link */
  video?: string;
}

export interface ForbiddenException {
  [key: string]: any;
}

export interface UnauthorizedException {
  [key: string]: any;
}

export interface Tokens {
  access: string;
  refresh: string;
}

export interface SignInDto {
  email: string;
  password: string;
}

/**
 * Roles of user
 */
export type UpdateUserDtoRole = (typeof UpdateUserDtoRole)[keyof typeof UpdateUserDtoRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUserDtoRole = {
  admin: 'admin',
  manager: 'manager',
  user: 'user',
} as const;

export interface UpdateUserDto {
  /** Email of user */
  email?: string;
  /** Full name of user */
  fullName?: string;
  /** Password of user */
  password?: string;
  /** Roles of user */
  role?: UpdateUserDtoRole;
}

export type QueryOrder = (typeof QueryOrder)[keyof typeof QueryOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QueryOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type OffsetPaginationResponseDataItem = { [key: string]: any };

export interface OffsetMeta {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  limit: number;
  page: number;
  pageCount: number;
}

export interface OffsetPaginationResponse {
  data: OffsetPaginationResponseDataItem[];
  meta: OffsetMeta;
}

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
  admin: 'admin',
  manager: 'manager',
  user: 'user',
} as const;

export interface User {
  /** The date that the entity was created */
  createdAt?: string;
  /** The date that the entity was soft-deleted. Nullable because it's not set until the entity is soft-deleted. */
  deletedAt?: string | null;
  /** Email of user */
  email: string;
  /** Full name of user */
  fullName: string;
  /** The unique id of the entity */
  id: string;
  /** To enable or disable the entity */
  isActive?: boolean;
  /** Phone number of user */
  phone?: string;
  role: UserRole;
}

/**
 * Roles of user
 */
export type CreateUserDtoRole = (typeof CreateUserDtoRole)[keyof typeof CreateUserDtoRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateUserDtoRole = {
  admin: 'admin',
  manager: 'manager',
  user: 'user',
} as const;

export interface CreateUserDto {
  /** Email of user */
  email: string;
  /** Full name of user */
  fullName: string;
  /** Password of user */
  password: string;
  /** Roles of user */
  role: CreateUserDtoRole;
}

/**
 * The overall status of the Health Check
 */
export type HealthResultStatus = { [key: string]: any };

export interface HealthResult {
  /** The details object contains information of every health indicator. */
  details: HealthIndicator;
  /** The error object contains information of each health indicator which is of status "down" */
  error?: HealthIndicator;
  /** The info object contains information of each health indicator which is of status "up" */
  info?: HealthIndicator;
  /** The overall status of the Health Check */
  status: HealthResultStatus;
}

export type HealthIndicatorStatus = (typeof HealthIndicatorStatus)[keyof typeof HealthIndicatorStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HealthIndicatorStatus = {
  up: 'up',
  down: 'down',
} as const;

export interface HealthStatus {
  message?: string;
  status: HealthIndicatorStatus;
}

export interface HealthIndicator {
  api: HealthStatus;
  database: HealthStatus;
  disk_health: HealthStatus;
  memory_heap: HealthStatus;
  memory_rss: HealthStatus;
  swagger: HealthStatus;
}

export interface InternalServerErrorException {
  [key: string]: any;
}

/**
 * @summary Http health check
 */
export const checkHttpHealth = () => {
  return httpClient<string>({ url: `/api/v1/health/check`, method: 'GET' });
};

/**
 * @summary Db, Disk health check
 */
export const checkDiskAndDbHealth = () => {
  return httpClient<HealthResult>({ url: `/api/v1/health`, method: 'GET' });
};

/**
 * @summary Create a user
 */
export const createUser = (createUserDto: BodyType<CreateUserDto>) => {
  return httpClient<User>({
    url: `/api/v1/users`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUserDto,
  });
};

/**
 * Returns paginated list of user(s)
 * @summary Find list of user(s)
 */
export const findAllUser = (params?: FindAllUserParams) => {
  return httpClient<FindAllUser200>({ url: `/api/v1/users`, method: 'GET', params });
};

/**
 * @summary Find a user
 */
export const findUserById = (id: string) => {
  return httpClient<User>({ url: `/api/v1/users/${id}`, method: 'GET' });
};

/**
 * @summary Update a user
 */
export const updateUserById = (id: string, updateUserDto: BodyType<UpdateUserDto>) => {
  return httpClient<User>({
    url: `/api/v1/users/${id}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateUserDto,
  });
};

/**
 * @summary Delete a user
 */
export const deleteUserById = (id: string) => {
  return httpClient<User>({ url: `/api/v1/users/${id}`, method: 'DELETE' });
};

/**
 * @summary Sign in
 */
export const signIn = (signInDto: BodyType<SignInDto>) => {
  return httpClient<Tokens | void>({
    url: `/api/v1/auth/signin`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: signInDto,
  });
};

/**
 * @summary Me
 */
export const verify = () => {
  return httpClient<User>({ url: `/api/v1/auth/verify`, method: 'GET' });
};

/**
 * Create a new product
 * @summary Create a product
 */
export const createProduct = (createProductDto: BodyType<CreateProductDto>) => {
  return httpClient<Product>({
    url: `/api/v1/products`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createProductDto,
  });
};

/**
 * Returns paginated list of product(s)
 * @summary Find list of product(s)
 */
export const findAllProduct = (params?: FindAllProductParams) => {
  return httpClient<FindAllProduct200>({ url: `/api/v1/products`, method: 'GET', params });
};

/**
 * @summary Find a product
 */
export const findProductById = (id: string) => {
  return httpClient<Product>({ url: `/api/v1/products/${id}`, method: 'GET' });
};

/**
 * @summary Update a product
 */
export const updateProductById = (id: string, updateProductDto: BodyType<UpdateProductDto>) => {
  return httpClient<Product>({
    url: `/api/v1/products/${id}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateProductDto,
  });
};

/**
 * @summary Delete a product
 */
export const deleteProductById = (id: string) => {
  return httpClient<void>({ url: `/api/v1/products/${id}`, method: 'DELETE' });
};

export const categoriesControllerCreate = (createCategoryDto: BodyType<CreateCategoryDto>) => {
  return httpClient<Category>({
    url: `/api/v1/categories`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createCategoryDto,
  });
};

/**
 * Returns paginated list of category(s)
 * @summary Find list of category(s)
 */
export const findAllCategory = (params?: FindAllCategoryParams) => {
  return httpClient<FindAllCategory200>({ url: `/api/v1/categories`, method: 'GET', params });
};

/**
 * @summary Find category by id
 */
export const findCategoryById = (id: string) => {
  return httpClient<Category>({ url: `/api/v1/categories/${id}`, method: 'GET' });
};

export const categoriesControllerUpdate = (id: string, updateCategoryDto: BodyType<UpdateCategoryDto>) => {
  return httpClient<CategoriesControllerUpdate200>({
    url: `/api/v1/categories/${id}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateCategoryDto,
  });
};

export const categoriesControllerRemove = (id: string) => {
  return httpClient<void>({ url: `/api/v1/categories/${id}`, method: 'DELETE' });
};

export const categoryFiltersControllerCreate = (createFilterDto: BodyType<CreateFilterDto>) => {
  return httpClient<CategoryFilters>({
    url: `/api/v1/categories/filters`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createFilterDto,
  });
};

/**
 * @summary Find category filters by category id
 */
export const findCategoryFiltersByCategoryId = (id: string) => {
  return httpClient<CategoryFilters>({ url: `/api/v1/categories/filters/${id}`, method: 'GET' });
};

/**
 * Create promotion
 * @summary Create promotion
 */
export const createPromotion = (createPromotionDto: BodyType<CreatePromotionDto>) => {
  return httpClient<Promotion>({
    url: `/api/v1/promotions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createPromotionDto,
  });
};

/**
 * Returns paginated list of promotions
 * @summary Find list of promotions
 */
export const findAllPromotion = () => {
  return httpClient<Promotion[]>({ url: `/api/v1/promotions`, method: 'GET' });
};

/**
 * Returns promotion
 * @summary Find one promotion
 */
export const findPromotionById = (id: string) => {
  return httpClient<Promotion>({ url: `/api/v1/promotions/${id}`, method: 'GET' });
};

/**
 * Update promotion
 * @summary Update promotion
 */
export const updatePromotionById = (id: string, updatePromotionDto: BodyType<UpdatePromotionDto>) => {
  return httpClient<Promotion>({
    url: `/api/v1/promotions/${id}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updatePromotionDto,
  });
};

/**
 * Remove promotion
 * @summary Remove promotion
 */
export const removePromotionById = (id: string) => {
  return httpClient<Promotion>({ url: `/api/v1/promotions/${id}`, method: 'DELETE' });
};

/**
 * @summary Create an order
 */
export const createOrder = (createOrderDto: BodyType<CreateOrderDto>) => {
  return httpClient<Order>({
    url: `/api/v1/orders`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createOrderDto,
  });
};

/**
 * Returns paginated list of order(s)
 * @summary Find list of order(s)
 */
export const findAllOrder = (params?: FindAllOrderParams) => {
  return httpClient<FindAllOrder200>({ url: `/api/v1/orders`, method: 'GET', params });
};

/**
 * @summary Find an order
 */
export const findOrderById = (id: string) => {
  return httpClient<Order>({ url: `/api/v1/orders/${id}`, method: 'GET' });
};

export type CheckHttpHealthResult = NonNullable<Awaited<ReturnType<typeof checkHttpHealth>>>;
export type CheckDiskAndDbHealthResult = NonNullable<Awaited<ReturnType<typeof checkDiskAndDbHealth>>>;
export type CreateUserResult = NonNullable<Awaited<ReturnType<typeof createUser>>>;
export type FindAllUserResult = NonNullable<Awaited<ReturnType<typeof findAllUser>>>;
export type FindUserByIdResult = NonNullable<Awaited<ReturnType<typeof findUserById>>>;
export type UpdateUserByIdResult = NonNullable<Awaited<ReturnType<typeof updateUserById>>>;
export type DeleteUserByIdResult = NonNullable<Awaited<ReturnType<typeof deleteUserById>>>;
export type SignInResult = NonNullable<Awaited<ReturnType<typeof signIn>>>;
export type VerifyResult = NonNullable<Awaited<ReturnType<typeof verify>>>;
export type CreateProductResult = NonNullable<Awaited<ReturnType<typeof createProduct>>>;
export type FindAllProductResult = NonNullable<Awaited<ReturnType<typeof findAllProduct>>>;
export type FindProductByIdResult = NonNullable<Awaited<ReturnType<typeof findProductById>>>;
export type UpdateProductByIdResult = NonNullable<Awaited<ReturnType<typeof updateProductById>>>;
export type DeleteProductByIdResult = NonNullable<Awaited<ReturnType<typeof deleteProductById>>>;
export type CategoriesControllerCreateResult = NonNullable<Awaited<ReturnType<typeof categoriesControllerCreate>>>;
export type FindAllCategoryResult = NonNullable<Awaited<ReturnType<typeof findAllCategory>>>;
export type FindCategoryByIdResult = NonNullable<Awaited<ReturnType<typeof findCategoryById>>>;
export type CategoriesControllerUpdateResult = NonNullable<Awaited<ReturnType<typeof categoriesControllerUpdate>>>;
export type CategoriesControllerRemoveResult = NonNullable<Awaited<ReturnType<typeof categoriesControllerRemove>>>;
export type CategoryFiltersControllerCreateResult = NonNullable<
  Awaited<ReturnType<typeof categoryFiltersControllerCreate>>
>;
export type FindCategoryFiltersByCategoryIdResult = NonNullable<
  Awaited<ReturnType<typeof findCategoryFiltersByCategoryId>>
>;
export type CreatePromotionResult = NonNullable<Awaited<ReturnType<typeof createPromotion>>>;
export type FindAllPromotionResult = NonNullable<Awaited<ReturnType<typeof findAllPromotion>>>;
export type FindPromotionByIdResult = NonNullable<Awaited<ReturnType<typeof findPromotionById>>>;
export type UpdatePromotionByIdResult = NonNullable<Awaited<ReturnType<typeof updatePromotionById>>>;
export type RemovePromotionByIdResult = NonNullable<Awaited<ReturnType<typeof removePromotionById>>>;
export type CreateOrderResult = NonNullable<Awaited<ReturnType<typeof createOrder>>>;
export type FindAllOrderResult = NonNullable<Awaited<ReturnType<typeof findAllOrder>>>;
export type FindOrderByIdResult = NonNullable<Awaited<ReturnType<typeof findOrderById>>>;
