import { createBarrier, createMutation } from '@farfetched/core';
import { attach, combine, createEffect, createEvent, createStore, sample, scopeBind } from 'effector';
import Cookies from 'js-cookie';

import { clientStarted } from '../lib/startup';
import { signIn, SignInDto } from './generated/api.generated';

export const $token = createStore<string | null>(null);

export const setToken = createEvent<string | null>();

sample({
  clock: setToken,
  target: $token,
});

export const $isAuthorized = combine($token, (token) => !!token);

export const setTokenToCookieFx = attach({
  source: $token,
  mapParams: (params, token) => token,
  effect: createEffect((token: string | null) => {
    if (token) {
      Cookies.set('token', token);
    } else {
      Cookies.remove('token');
    }
  }),
});

sample({
  clock: setToken,
  target: setTokenToCookieFx,
});

sample({
  clock: clientStarted,
  target: createEffect(() => {
    const token = Cookies.get('token');

    if (token) {
      setToken(token);
    }
  }),
});

// eslint-disable-next-line effector/enforce-store-naming-convention
export const authBarrier = createBarrier({
  active: combine($token, (token) => !!token),
});

// export const refreshTokenMutation = createMutation({
//   effect: createEffect((token: string) => {
//     return refreshToken(token);
//   }),
// })

export const signInMutation = createMutation({
  effect: createEffect((signInDto: SignInDto) => {
    return signIn(signInDto);
  }),
});

export const signUpMutation = createMutation({
  effect: createEffect((signInDto: SignInDto) => {
    return signIn(signInDto);
  }),
});

sample({
  clock: signInMutation.finished.success,
  fn: (tokens) => tokens.result?.access ?? null,
  target: $token,
});
