import type { OptionalQuery as OptionalQuery_1nc443a } from '../../pages/catalog/[categoryId]/products/index.page';
import type { Query as Query_la8sce } from '../../pages/catalog/search/index.page';

const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  "auth": {
    "signin": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/auth/signin' as const, hash: url?.hash })
    },
    "signup": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/auth/signup' as const, hash: url?.hash })
    }
  },
  "cart": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/cart' as const, hash: url?.hash })
  },
  "catalog": {
    _categoryId: (categoryId: string | number) => ({
      "products": {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/catalog/[categoryId]/products/[id]' as const, query: { categoryId, id }, hash: url?.hash })
        }),
        $url: (url?: { query?: OptionalQuery_1nc443a | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/catalog/[categoryId]/products' as const, query: { categoryId, ...url?.query }, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/catalog/[categoryId]' as const, query: { categoryId }, hash: url?.hash })
    }),
    "search": {
      $url: (url: { query: Query_la8sce, hash?: string | undefined }) => ({ pathname: '/catalog/search' as const, query: url.query, hash: url.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/catalog' as const, hash: url?.hash })
  },
  "compare": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/compare' as const, hash: url?.hash })
  },
  "delivery_and_payment": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/delivery-and-payment' as const, hash: url?.hash })
  },
  "favorites": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/favorites' as const, hash: url?.hash })
  },
  "offer": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/offer' as const, hash: url?.hash })
  },
  "personal_data": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/personal-data' as const, hash: url?.hash })
  },
  "privacy_policy": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/privacy-policy' as const, hash: url?.hash })
  },
  "requisites": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/requisites' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  dom_stankov_logo_svg: '/dom-stankov-logo.svg',
  favicon_ico: '/favicon.ico',
  fonts: {
    Montserrat_Italic_ttf: '/fonts/Montserrat-Italic.ttf',
    Montserrat_Regular_ttf: '/fonts/Montserrat-Regular.ttf',
    Montserrat_SemiBold_ttf: '/fonts/Montserrat-SemiBold.ttf',
    good_timing_bd_ttf: '/fonts/good-timing-bd.ttf'
  },
  images: {
    $33936017_svg: '/images/33936017.svg',
    dom_stankov_logo_svg: '/images/dom-stankov-logo.svg',
    stock_1_webp: '/images/stock-1.webp',
    vk_svg: '/images/vk.svg'
  },
  next_svg: '/next.svg',
  robots_txt: '/robots.txt',
  sitemap_0_xml: '/sitemap-0.xml',
  sitemap_xml: '/sitemap.xml',
  vercel_svg: '/vercel.svg',
  videos: {
    video_on_index_mov: '/videos/video-on-index.mov',
    video_on_index_webm: '/videos/video-on-index.webm'
  }
} as const;

export type StaticPath = typeof staticPath;
