import wretch from 'wretch';
import FormDataAddon from 'wretch/addons/formData';
import QueryStringAddon from 'wretch/addons/queryString';

import { env } from '@/shared/config/env.mjs';

import { NetworkException } from './exceptions';

export const wretchClient = wretch(env.NEXT_PUBLIC_APP_URL)
  .addon(QueryStringAddon)
  .addon(FormDataAddon)
  .catcher(500, () => {
    throw new NetworkException('Internal Server Error').toJSON();
  })
  .catcher(404, () => {
    throw new NetworkException('NotFound').toJSON();
  })
  .catcher(429, () => {
    throw new NetworkException('TooManyRequests').toJSON();
  })
  .resolve((_) =>
    _.fetchError(() => {
      throw new NetworkException('Network Error').toJSON();
    }),
  );

export interface HTTPClientOptions {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params?: any;
  headers?: Record<string, string>;
  data?: BodyType<unknown>;
  responseType?: string;
}

export const httpClient = async <Data>(options: HTTPClientOptions) => {
  const { url, method, params, data, headers = {} } = options;

  const methode = method.toLowerCase() as Lowercase<typeof method>;

  const response = await wretchClient
    .url(url)
    .headers(headers)
    .query(params)
    .body(JSON.stringify(data))
    [methode]()
    .text();

  return response ? (JSON.parse(response) as Data) : null;
};

export type ErrorType<Error> = NetworkException;

export type BodyType<BodyData> = BodyData;
